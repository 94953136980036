import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Label = css`
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #888888;
`;

export const Options = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  background-color: #fff;
  color: #4c5862;
`;

export const MyIcon = styled.i`
  position: absolute;
  top: 32% !important;
`;
export const Title = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #0a043c;
  margin: 0 auto;
  margin-bottom: 1rem;
`;
export const Textp = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #544f77;
  text-align: left;
  /* display: -webkit-inline-box; */
  &:after {
    display: inline-flex;
    flex-direction: row;
    margin-left: 0.5rem;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    align-items: center;
    justify-content: center;
    background-color: #c5dcff;
    content: "?";
  }
`;
export const VDivisor = css`
  border: 1px solid #d2d2d2;
  width: 2.4rem;
  transform: rotate(90deg);
  position: absolute;
  top: 48%;
  right: 1.3rem;
  /* transform-origin: left top; */
`;

export const CheckButton = css`
  width: 150px;
  border-radius: 10px;
`;

interface IMyImage {
  disabled: boolean;
}
export const MyImage = styled.img<IMyImage>`
  filter: ${(props) =>
    props.disabled
      ? "invert(92%) sepia(94%) saturate(1778%) hue-rotate(178deg) brightness(104%) contrast(111%)"
      : ""};
  // color: ${(props) => (props.disabled ? "#CCE3FF" : "#E6F1FF")};
  margin-right: 0.5rem;
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  height: 88%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 100%;
  overflow-y: scroll;
  padding: 0 15px;
`;
export const ContainerButton = styled.div`
  max-height: 12%;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

export const DivInputSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
