import { Button, DataTable, Dropdown } from "../..";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Text } from "../../Text/text";
import { v4 as uuidv4 } from "uuid";
import {
  setBotIdActive,
  setCustomEntitiesModalOpen,
  setCustomEntityUpdating,
  setEntityIdUpdate,
  setInsertEntity,
  setTransferModal,
  toggleOpenEntityDialog,
  setCustomEntities,
  setUpdateDataTable
} from "../../../redux/slices/flowInteractions";
import entityTypesServices from "../../../services/entityTypesService";
import {
  DivIconRounded,
  TextTitle,
  Button as MyButtton,
  MyChip,
  ModalImport
} from "./styles";
import { CustomEntities } from "./CustomEntities";
import { EntitiesAdmin, background, girl } from "../../../assets";
import { Column } from "primereact/column";
import { LetterCircle } from "../../gestaoBot/components/LetterCircle";
import DialogPadrao from "../../DialogPadrao";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { css } from "@emotion/react";
import { AuthContextType, useAuth } from "../../../auth/auth-provider";
import {
  DataTableFilterMatchModeType,
  DataTableGlobalFilterType
} from "primereact/datatable";

interface Result {
  botId: number;
  botName: string;
  id: number;
  inputs: number;
  name: string;
  status: boolean;
  updatedBy: string;
}
interface IEntities {
  result: Result[];
  total: number;
}

// type DataTableFilterMatchModeType =
//   | "startsWith"
//   | "contains"
//   | "notContains"
//   | "endsWith"
//   | "equals"
//   | "notEquals"
//   | "in"
//   | "lt"
//   | "lte"
//   | "gt"
//   | "gte"
//   | "between"
//   | "dateIs"
//   | "dateIsNot"
//   | "dateBefore"
//   | "dateAfter"
//   | "custom";

// interface DataTableFilterMeta {  [key: string]: DataTableFilterMetaData | DataTableOperatorFilterMetaData;
// }

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};
export const EntitiesPageComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const [inputRefId, setInputRefId] = useState("");
  const { getAllEntities, searchEntities, uploadEntities } =
    entityTypesServices();
  const effectRan = useRef(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const toast = useRef<Toast>(null);

  const { user, hasAnyPermission } = useAuth() as AuthContextType;
  const debouncedText = useDebounce(globalFilterValue, 600);

  const modalOpen = useAppSelector(
    (state) => state.flowInteractions.entityModalOpen
  );
  const customModalOpen = useAppSelector(
    (state) => state.flowInteractions.customEntityModalOpen
  );
  const selectedWorksPaces = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );
  const updateDatas = useAppSelector(
    (state) => state.flowInteractions.updateDataTable
  );

  const [modalImport, setModalImport] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [entities, setEntities] = useState<IEntities>({} as IEntities);
  const [selectedEntities, setSelectedEntities] = useState<Result[]>([]);
  const [loading, setLoading] = useState(false);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 20,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      globalFilterValue: {
        value: "" as DataTableGlobalFilterType,
        matchMode: "contains" as DataTableFilterMatchModeType
      }
    }
  });

  const fetchSearch = async () => {
    try {
      if (debouncedText?.length > 0) {
        const results = await searchEntities({
          limit: lazyParams.rows,
          offset: lazyParams.first,
          name: debouncedText,
          workspaces: selectedWorksPaces
        });
        setEntities(results);
      } else {
        const results = await getAllEntities({
          limit: lazyParams.rows,
          offset: lazyParams.first,
          workspaces: selectedWorksPaces
        });
        setEntities(results);
      }
    } catch (error) {
      toast.current?.show({
        severity: "error",
        summary: "Erro ao buscar entidades.",
        detail:
          "Não é possível buscar entidades neste momento. Por favor tente mais tarde."
      });
    }
  };

  const sendFile = async () => {
    const uuid = uuidv4();
    const form = new FormData();
    form.append(
      "entities",
      Array.from(selectedEntities.map((el) => el.id)).toString()
    );
    form.append("file", file as File);
    setLoading(true);
    try {
      const retorno = await uploadEntities(form);

      // eslint-disable-next-line no-negated-condition
      // dispatch(setInsertEntity(true));
      // eslint-disable-next-line no-negated-condition
      if (retorno.message !== "Erro ao importar arquivo") {
        toast.current?.show({
          life: 5000,
          severity: "success",
          summary: "Sucesso",
          detail: "Entidades inseridas com sucesso."
        });
        setFile(undefined);
        setInputRefId(uuid);
        form.delete("entities");
        form.delete("file");
        setSelectedEntities([]);
        setLoading(false);
        fetchSearch();
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Erro no upload de arquivo.",
          detail: retorno?.message
        });
        setFile(undefined);
        setInputRefId(uuid);
        form.delete("entities");
        form.delete("file");
        setSelectedEntities([]);
        setLoading(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.current?.show({
        severity: "error",
        summary: "Erro no upload de arquivo.",
        detail: error?.message
      });
      setFile(undefined);
      setInputRefId(uuid);
      form.delete("entities");
      form.delete("file");
      setSelectedEntities([]);
      setLoading(false);
    }
  };
  // const [globalFilterValue, setGlobalFilterValue] = useState("");

  useEffect(() => {
    if (effectRan.current) {
      fetchSearch();
    }
    return () => {
      effectRan.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lazyParams,
    selectedWorksPaces,
    modalOpen,
    customModalOpen,
    debouncedText,
    updateDatas
  ]);

  const onPage = (event: never) => {
    setLazyParams(event);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFilter = (event: any) => {
    event.first = 0;
    setLazyParams(event);
  };

  const OpenEntityDialog = useAppSelector(
    (state) => state.flowInteractions.openEntityDialog
  );

  const handleOpenDialog = () => {
    dispatch(toggleOpenEntityDialog());
  };

  const inputStyles = css`
    display: flex;
    width: 342px;

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  `;

  const handleImport = () => {
    if (selectedEntities.length <= 0) {
      inputRef.current?.click();
    } else {
      setModalImport(true);
      setFile(undefined);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setUpdateDataTable(false));
    };
  }, [updateDatas]);

  useEffect(() => {
    if (!loading) {
      setModalImport(false);
    }
  }, [loading]);

  const renderHeader = () => {
    const processCSV = (file: File): Promise<string[][]> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const text = event.target?.result as string;
          const rows = text
            .split("\n")
            .map((row) => row.replace(/\r/g, "").trim());

          const parsedRows = rows.map((row) =>
            row.split(",").map((field) => field.trim().replace(/"/g, ""))
          );
          resolve(parsedRows);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
      });
    };

    useEffect(() => {
      if (file) {
        if (file.type !== "text/csv") {
          toast.current?.show({
            severity: "error",
            summary: "Erro no arquivo",
            detail: "O arquivo selecionado não é um CSV."
          });
          setFile(undefined);
          return;
        }

        processCSV(file)
          .then((rows) => {
            dispatch(setCustomEntities(rows));
            if (selectedEntities.length <= 0) {
              dispatch(toggleOpenEntityDialog());
            } else {
              sendFile();
            }
          })
          .catch((error) => {
            toast.current?.show({
              severity: "error",
              summary: "Erro no arquivo CSV",
              detail: error.message
            });
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    return (
      <div
        css={css`
          background-color: #ffffff;
          border-radius: 10px 10px 0px 0px;
        `}
      >
        <div
          css={css`
            border-radius: 10px 10px 0px 0px;
            padding: 8px 12px;
            display: flex;
            gap: 0.5rem;
            flex-wrap: wrap;
          `}
        >
          <span className="p-input-icon-left" css={inputStyles}>
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={(e) => setGlobalFilterValue(e.target.value)}
              placeholder="Buscar"
              style={{ width: "100%" }}
            />
          </span>
          {user.admin || hasAnyPermission("entity_import") ? (
            <>
              <Button
                label="Importar"
                className="p-button-outlined"
                icon="pi pi-download"
                // loading={loading}
                // disabled={Boolean(!selectedEntities?.length)}
                onClick={handleImport}
              />

              <input
                key={inputRefId}
                style={{ display: "none" }}
                type="file"
                ref={inputRef}
                accept=".csv"
                multiple={false}
                onChange={(e) => {
                  setFile(e.target?.files?.[0]);
                  e.target.value = "";
                }}
              />
            </>
          ) : null}
        </div>
      </div>
    );
  };
  const header = renderHeader();
  const handleEditEntity = (entityId: number) => {
    dispatch(setInsertEntity(false));
    dispatch(
      setBotIdActive(
        entities?.result?.filter((entity) => entity.id === entityId)[0]?.botId
      )
    );
    dispatch(setCustomEntityUpdating(true));
    dispatch(setEntityIdUpdate(entityId));
    handleOpenDialog();
    dispatch(setCustomEntitiesModalOpen(true));
    dispatch(setTransferModal(false));
  };
  const actionBodyTemplate = (rowData: { id: number }) => {
    return (
      <button
        css={MyButtton}
        type="button"
        onClick={() => handleEditEntity(rowData.id)}
      >
        Editar
      </button>
    );
  };

  const statusBodyTemplate = (rowData: { status: boolean }) => {
    return (
      <MyChip active={rowData.status}>
        {rowData.status ? "Ativo" : "Inativo"}
      </MyChip>
    );
  };
  const responsavelBodyTemplate = (rowData: { updatedBy: string }) => {
    return (
      <div className="flex align-items-baseline">
        <LetterCircle initial={rowData.updatedBy} />
        <div className="ml-1">{rowData.updatedBy}</div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex w-full p-4 flex-column justify-content-start align-items-start overflow-y-scroll">
        <div className="flex gap-2">
          <DivIconRounded>
            <img src={EntitiesAdmin} alt="Entities-Icon" />
          </DivIconRounded>
          <TextTitle>Gestão de entidades</TextTitle>
        </div>
        <div className="my-2">
          <Text>
            Entidades permitem armazenar informações em grupos, você pode criar
            listas personalizadas e usar em um diálogo.
          </Text>
        </div>
        <DataTable
          dataKey="id"
          style={{ width: "100%", borderRadius: 0 }}
          value={entities.result}
          lazy={true}
          paginator={true}
          first={lazyParams.first}
          rows={lazyParams.rows}
          totalRecords={entities.total}
          onPage={onPage}
          // onSort={onSort}
          onFilter={onFilter}
          filterDelay={600}
          filterDisplay="menu"
          emptyMessage="Nenhum resultado encontrado"
          filters={lazyParams.filters}
          // rowGroupFooterTemplate={foot}
          globalFilterFields={["id", "name"]}
          responsiveLayout="scroll"
          // paginatorTemplate={template2}
          // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          paginatorTemplate={{
            layout:
              "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
            RowsPerPageDropdown: (options) => {
              const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 }
              ];
              return (
                <>
                  <span
                    css={css`
                      user-select: none;
                      color: #6e6b7b;
                    `}
                  >
                    Por página:
                  </span>

                  <Dropdown
                    style={{ width: "5rem" }}
                    value={options.value}
                    options={dropdownOptions}
                    onChange={options.onChange}
                    css={css`
                      .p-inputtext {
                        color: #6e6b7b;
                      }
                    `}
                  />
                </>
              );
            }
          }}
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
          header={header}
          rowsPerPageOptions={[5, 20, 50, 100]}
          selection={selectedEntities}
          selectionMode="checkbox"
          rowHover={true}
          onSelectionChange={(e) => {
            setSelectedEntities(e.value);
          }}
        >
          {user.admin || hasAnyPermission("entity_import") ? (
            <Column selectionMode="multiple" />
          ) : null}
          <Column
            field="id"
            header="Id"
            // body={activeBodyTemplate}
          ></Column>
          <Column
            field="name"
            header="Nome"
            // body={responsavelBodyTemplate}
          ></Column>
          <Column
            field="botId"
            header="Bot"
            // body={responsavelBodyTemplate}
          ></Column>
          <Column
            field="botName"
            header="Nome do Bot"
            // body={responsavelBodyTemplate}
          ></Column>
          <Column
            field="inputs"
            header="Entradas"
            // body={responsavelBodyTemplate}
          ></Column>
          <Column
            field="status"
            header="Status"
            align="center"
            body={statusBodyTemplate}
          ></Column>
          <Column
            field="updatedBy"
            header="Atualizado por"
            body={responsavelBodyTemplate}
          ></Column>
          <Column field="id" header="Ação" body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <DialogPadrao
        displayDialog={OpenEntityDialog}
        onHide={() => dispatch(toggleOpenEntityDialog())}
        contentStyle={{ height: "420px" }}
        headerStyle={{
          backgroundColor: "#ff0025"
        }}
      >
        <div className="pl-2 pr-2 rigtContentFake">
          <CustomEntities onClick={handleOpenDialog} />
        </div>
        <div className="leftContent">
          <div className="flex justify-content-center">
            <img src={girl} alt="imgBot" />
          </div>
          <div className="p-2">
            <Text
              color="#FFF"
              fontWeight={500}
              fontSize="0.8125rem"
              lineHeight="1rem"
            >
              Qual a importância da utilização de Entidades?
            </Text>
            <div className="mt-2">
              <Text
                color="#FFF"
                fontSize="0.6875rem"
                fontStyle="thin"
                lineHeight="14px"
              >
                As marcas mais bem sucedidas sabem como interagir com seus
                clientes, não importa onde estejam. Eles não contam com
                estratégias de marketing unidimensionais que simplesmente
                dependem de interações no site.
              </Text>
            </div>
          </div>
        </div>
      </DialogPadrao>
      <Dialog
        visible={modalImport}
        onHide={() => {
          setModalImport(false);
        }}
        draggable={false}
      >
        <ModalImport>
          Você deseja alterar todas as entidades selecionadas?
          <div className="divButton">
            <Button label="Sim" onClick={() => inputRef.current?.click()} />
            <Button
              label="Não"
              style={{ background: "#d3d3d3", borderColor: "#d3d3d3" }}
              onClick={() => {
                setModalImport(false);
              }}
            />
          </div>
          {loading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 3,
                position: "fixed",
                width: "10rem",
                height: "10rem"
              }}
            >
              <ProgressSpinner />
            </div>
          )}
        </ModalImport>
      </Dialog>
    </>
  );
};
