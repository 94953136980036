import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  setAddMoreTransferOptions,
  toggleTransferModal
} from "../../../../redux/slices/flowInteractions";
import {
  nodeTransfer,
  setNodeTransferData,
  changeNode
} from "../../../../redux/slices/nodes";
import {
  BlueBar,
  BotTransfer,
  BoxAgentNotice,
  CancelButton,
  CheckButton,
  ContainerButton,
  ContainerButtonEnd,
  ContainerContent,
  DataBotTransfer,
  DisabledButton,
  Divisor,
  IconButton,
  Modal,
  MyImage,
  Overlay,
  QuestionStyle,
  SaveButton,
  Span
} from "./styles";
import { Automation, bot, BotBlue, Check, Humans } from "../../../../assets";
import { ITransferNodeDTO } from "../../../../components/react-flow-renderer/NodeData";
import { Text } from "../../../../components/Text";
import Dropdown from "../../../../components/dropdown";
import WorkspaceUserService from "../../../../services/workspaceUserService";
import { DropdownChangeParams } from "primereact/dropdown";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
// import { TreeSelect, TreeSelectSelectionKeys } from "primereact/treeselect";
import { Dialog } from "primereact/dialog";
import { onEdgesChange } from "../../../../redux/slices/edges";
import { changedPhoneComponent } from "../../../../redux/slices/flowupdates";
import { EdgeChange } from "react-flow-renderer";
import { setUsersFromWorkspaces } from "../../../../redux/slices/workspaces";
import { ProgressSpinner } from "primereact/progressspinner";
import { IResponseGetChatbots } from "../../../../types/chatbots";
import BotsServices from "../../../../services/bots";
import { setRChatBots } from "../../../../redux/slices/chatbots";
import { InputTextarea } from "primereact/inputtextarea";
import { useParams } from "react-router-dom";

export const TransferModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuth() as AuthContextType;
  const { companyId } = user;
  const { company, setCompany } = useAuth() as AuthContextType;
  const [visible, setVisible] = useState(false);
  const [botMOdal, setBotModal] = useState(false);
  const { getUsersByWorkspace } = WorkspaceUserService();
  const transferModalOpen = useAppSelector(
    (state) => state.flowInteractions.transferModal
  );
  const bots = useAppSelector((state) => state.chatbots.chatbots);
  const { botId } = useParams();
  const [buttonSaveDisabled, setButtonSaveDisabled] = useState(true);
  const toast = useRef<Toast>(null);
  // old Way
  // const workspaces = useAppSelector((state) => state.workspaces);
  const workspaces = user?.workspaces?.workspaces;
  const [transferType, setTransferType] = useState({
    human: false,
    bot: false,
    external: false
  });
  const [loading, setLoading] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState<{
    id: number;
    name: string;
  }>({ id: -1, name: "" });
  const [selectedTypeTransfer, setSelectedTypeTransfer] = useState<{
    id: number;
    name: string;
  }>({ id: -1, name: "" });

  // const [userNameColabEspecifico, setUserNameColabEspecifico] = useState("")
  const nodes = useAppSelector((state) => state.nodes);
  const edges = useAppSelector((state) => state.edges);
  const addingMoreTransferOptions = useAppSelector(
    (state) => state.flowInteractions.addMoreTransferOptions
  );
  const nodeSelected = nodes.find((node) => node.selected === true);
  const botTransferMedia = nodeSelected?.data?.transferData?.botTransferMedia;
  const [valueBotTransferMedia, setValueBotTransferMedia] = useState(
    botTransferMedia || "WHATSAPP_QRCODE"
  );

  const messageTransfer = nodeSelected?.data?.transferData?.botTransferMessage;
  const [valueMessageTransfer, setValueMessageTransfer] = useState(
    messageTransfer || ""
  );
  const transferedWebhook = nodeSelected?.data?.transferData?.thirdPartySystem;
  const transferedOmnichannel =
    nodeSelected?.data?.transferData?.workspaceOnWebhook ||
    Number(nodeSelected?.data?.transferData?.workspaceId) > 0;

  const [selectedUser, setSelectedUser] = useState<{
    id: number;
    name: string;
  }>({ id: -1, name: "" });

  const [selectedWorkspaceOnWebhook, setSelectedWorkspaceOnWebhook] = useState<{
    id: number;
    name: string;
  }>({ id: -1, name: "" });
  const [botSelected, setBotSelected] = useState<{
    id: number;
    name: string;
  }>({ id: -1, name: "" });
  const [justOnlineUsers, setJustOnlineUsers] = useState<boolean>(true);
  // atributosInodeDto
  // const [transferData, setTransferData] = useState<ITransferNodeDTO>({
  //   onlyOnlineUser: true,
  //   queue: 0,
  //   workspaceId: -1,
  //   userId: -1,
  //   thirdPartySystem: false,
  //   workspaceOnWebhook: false,
  // });
  const transferDataLocal = {
    queue: 0,
    workspaceId: selectedWorkspace.id === -1 ? null : selectedWorkspace.id,
    userId: selectedUser.id === -1 ? null : selectedUser.id,
    userName: selectedUser.name ?? "",
    onlyOnlineUser: justOnlineUsers,
    thirdPartySystem: nodeSelected?.data?.transferData?.thirdPartySystem,
    workspaceOnWebhook: selectedWorkspaceOnWebhook.id === 2,
    botId: selectedUser.id !== -1 ? null : botSelected?.id ?? null,
    botName: selectedUser.id !== -1 ? "" : botSelected?.name ?? "",
    botTransferMessage: selectedUser.id !== -1 ? "" : valueMessageTransfer,
    botTransferMedia:
      selectedUser.id !== -1 ? "WHATSAPP_QRCODE" : valueBotTransferMedia
  };
  const typeTransfer = [
    { id: 1, name: "Colaborador Específico" },
    { id: 2, name: "Sequencial" }
  ];
  const typeWorkspaceOnWebhook = [
    { id: 1, name: "Selecionar Workspace" },
    { id: 2, name: "Consultar Webhook" }
  ];
  const [usersFromWorksPaceSelected, setUsersFromWorksPaceSelected] = useState(
    []
  );

  useEffect(() => {
    if (selectedTypeTransfer.id === 1 || selectedTypeTransfer.id === 2) {
      setBotSelected({ id: -1, name: "" });
      setValueMessageTransfer("");
      setValueBotTransferMedia("WHATSAPP_QRCODE");
    }
  }, [selectedTypeTransfer]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        nodeSelected?.data?.transfer &&
        nodeSelected?.data?.transferData?.userId !== -1
      ) {
        const userId = nodeSelected?.data?.transferData?.userId as number;
        const wsId = nodeSelected?.data?.transferData?.workspaceId as number;
        const wsName = workspaces?.filter((el) => el.id === wsId)[0]?.name;
        setTransferType({ human: true, bot: false, external: false });
        // setJustOnlineUsers(nodeSelected?.data?.transferData?.onlyOnlineUser as boolean);
        setSelectedWorkspace({ id: wsId, name: wsName as string });
        setSelectedTypeTransfer({ id: 1, name: "Colaborador Específico" });
        // setUserNameColabEspecifico(nodeSelected?.data?.transferData?.userName as string);
        if (wsId) {
          const retorno = await getUsersByWorkspace(companyId, wsId);
          dispatch(setUsersFromWorkspaces(retorno));
          const labelLocal = retorno?.filter(
            (el: { id: number }) => el?.id === userId
          )[0]?.name as string;
          setUsersFromWorksPaceSelected(retorno);
          setSelectedUser({ id: userId, name: labelLocal });
          // setUsersFromWorksPaceSelected(retornoTree)
        }
      } else if (
        nodeSelected?.data?.transfer &&
        nodeSelected?.data?.transferData?.workspaceId
      ) {
        const wsId = nodeSelected?.data?.transferData?.workspaceId;
        const wsName = workspaces?.filter((el) => el.id === wsId)[0]?.name;
        setTransferType({ human: false, bot: false, external: true });
        setSelectedWorkspace({ id: wsId, name: wsName as string });
        setJustOnlineUsers(nodeSelected?.data?.transferData?.onlyOnlineUser);
        setSelectedTypeTransfer({ id: 2, name: "Sequencial" });
        setSelectedUser({ id: -1, name: "" });
      } else if (
        nodeSelected?.data?.transfer &&
        nodeSelected?.data?.transferData?.workspaceId
      ) {
      } else {
        setTransferType({ human: false, bot: false, external: false });
        setSelectedWorkspace({ id: -1, name: "" });
        setSelectedTypeTransfer({ id: -1, name: "" });
        setSelectedUser({ id: -1, name: "" });
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferModalOpen]);
  // const handleChangeBooleans = (name: string, value: boolean) => {
  //   setTransferData({ ...transferData, [name]: value });
  // };
  const handleSave = () => {
    dispatch(setNodeTransferData(transferDataLocal));
    dispatch(nodeTransfer(true));
    dispatch(toggleTransferModal());
    dispatch(setAddMoreTransferOptions(false));
    setVisible(false);
  };

  const handleSetExternal = () => {
    // handleChangeBooleans("thirdPartySystem", true);
    setTransferType({ human: false, bot: false, external: true });
    const updatedTransferData = {
      ...transferDataLocal,
      thirdPartySystem: true,
      botId: nodeSelected?.data.transferData?.botId || transferDataLocal.botId,
      botName:
        nodeSelected?.data.transferData?.botName || transferDataLocal.botName,
      botTransferMessage:
        nodeSelected?.data.transferData?.botTransferMessage ||
        transferDataLocal.botTransferMessage
    };
    dispatch(setNodeTransferData(updatedTransferData));
    dispatch(nodeTransfer(true));
    dispatch(setAddMoreTransferOptions(false));
    dispatch(toggleTransferModal());
  };
  const handleTypeWorkspaceOnWebhook = (e: DropdownChangeParams) => {
    setSelectedWorkspaceOnWebhook(e.value);
    if (e.value.id === 1) {
      setSelectedTypeTransfer({ id: -1, name: "" });
    }
    if (e.value.id === 2) {
      setSelectedTypeTransfer({ id: 2, name: "Sequencial" });
      setSelectedUser({ id: -1, name: "" });
      setSelectedWorkspace({ id: -1, name: "" });
    }
  };
  const handleSelectedTypeTransfer = (e: DropdownChangeParams) => {
    if (e.value.id === 2 && nodeSelected?.data?.transferData?.userId !== -1) {
      setSelectedUser({ id: -1, name: "" });
    }
    setSelectedTypeTransfer(e.value);
  };

  const handleSelectedWorkspace = async (e: DropdownChangeParams) => {
    setSelectedWorkspace(e.value);

    if (company) {
      const admUser = await getUsersByWorkspace(company.id, e.value.id);
      setUsersFromWorksPaceSelected(admUser);
    } else {
      const defaultUser = await getUsersByWorkspace(companyId, e.value.id);
      setUsersFromWorksPaceSelected(defaultUser);
    }
  };
  const handleAskSave = () => {
    handleSave();
  };
  const Accept = () => {
    if (nodeSelected?.type === "endNode") {
      handleSave();
    } else {
      if (nodeSelected?.type === "defaultNode") {
        const newNode = {
          ...nodeSelected,
          type: "endNode",
          data: {
            label: nodeSelected?.data.label,
            userInteractions: [],
            botInteractions: nodeSelected?.data.botInteractions,
            entityTypeId: -1,
            entityTypeName: "",
            sourceEntityNodeId: "",
            webhookCall: nodeSelected?.data?.webhookCall
          }
        };
        const edgesToDelete = edges
          // eslint-disable-next-line array-callback-return, consistent-return
          .map((edge) => {
            if (edge.source.includes(nodeSelected?.id)) {
              return {
                id: edge.id,
                type: "remove"
              };
            }
          })
          ?.filter((notUndefineds) => notUndefineds !== undefined);
        dispatch(onEdgesChange(edgesToDelete as EdgeChange[]));
        dispatch(changedPhoneComponent(true));
        dispatch(changeNode({ nodeId: nodeSelected?.id, node: newNode }));
        setVisible(false);
      }
      handleSave();
    }
  };
  const Reject = () => {
    setVisible(false);
    dispatch(toggleTransferModal());
  };

  useEffect(() => {
    const filledFields =
      botSelected.id !== -1 &&
      valueBotTransferMedia &&
      valueMessageTransfer !== "";

    setButtonSaveDisabled(!filledFields);
  }, [botSelected, valueBotTransferMedia, valueMessageTransfer]);

  return (
    <>
      <div
        style={{ display: transferModalOpen ? "block" : "none" }}
        css={Overlay}
      />
      <Dialog
        header="Confirmação"
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <div className="flex ">
          <span className="pi pi-exclamation-triangle mr-2" />
          <p>{`Todas as informações contidas no node ${
            nodeSelected?.type === "endNode" ? "tipo end " : "tipo default "
          } serão perdidas.`}</p>
        </div>
        <p>Deseja prosseguir?</p>
        <div className="flex mt-4 mb-4 flex-row-reverse">
          <Button label="Sim" className="p-button-info" onClick={Accept} />
          <Button
            label="Não"
            className="p-button-danger ml-2 mr-2"
            onClick={Reject}
          />
        </div>
      </Dialog>
      <dialog
        style={{ display: transferModalOpen ? "flex" : "none" }}
        open={transferModalOpen}
        css={Modal}
      >
        {transferType.bot && !addingMoreTransferOptions ? (
          // &&
          // selectedWorkspace.id > 0 &&
          // selectedTypeTransfer.id === 1 &&
          // selectedWorkspaceOnWebhook.id === 1 &&
          // !addingMoreTransferOptions ?

          <BotTransfer style={{ display: botMOdal ? "block" : "none" }}>
            <div style={{ height: "36%" }}></div>
            <DataBotTransfer>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    padding: "5px 0",
                    width: "100%"
                  }}
                >
                  <Button
                    icon="pi pi-times"
                    className="p-button-outlined"
                    style={{ color: "#4C5862", borderColor: "transparent" }}
                    onClick={() => setBotModal(false)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    padding: "10px 20px 0 20px",
                    gap: "5px"
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                      }}
                    >
                      Transferir Atendimento
                      <span style={{ marginLeft: "5px" }} css={QuestionStyle}>
                        ?
                      </span>
                    </div>
                  </span>
                  <span style={{ color: "#4C5862" }}>
                    Informe para qual robô você deseja transferir esta
                    oportunidade.
                  </span>
                </div>

                <div style={{ padding: "0 10px" }}>
                  <Dropdown
                    style={{
                      width: "100%",
                      marginTop: "1rem",
                      lineHeight: "1.3125rem"
                    }}
                    value={botSelected}
                    options={bots.filter(
                      (bot) => bot.id !== parseInt(botId || "-1")
                    )}
                    onChange={(e) => setBotSelected(e.value)}
                    optionLabel="name"
                    placeholder="Informe um robô"
                    filter={true}
                  />
                  <Dropdown
                    style={{
                      width: "100%",
                      marginTop: "1rem",
                      lineHeight: "1.3125rem"
                    }}
                    // value={valueBotTransferMedia}
                    value="WHATSAPP_QRCODE"
                    // options={usersFromWorksPaceSelected}
                    options={[
                      { name: "WhatsApp QR Code", value: "WHATSAPP_QRCODE" }
                    ]}
                    // onChange={(e) => setValueBotTransferMedia(e.value)}
                    onChange={() => {}}
                    optionLabel="name"
                    placeholder="Selecione um canal"
                    filter={true}
                  />
                  <InputTextarea
                    style={{
                      width: "100%",
                      marginTop: "1rem",
                      lineHeight: "1.5rem"
                    }}
                    value={valueMessageTransfer}
                    onChange={(e) => setValueMessageTransfer(e.target.value)}
                    rows={3}
                    autoResize={true}
                    maxLength={500}
                    placeholder="Informe a mensagem inicial da transferência."
                  />
                  <Divisor style={{ margin: "1rem 0" }} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around"
                  }}
                >
                  <Button
                    label="CANCELAR"
                    className="p-button-outlined"
                    style={{
                      color: "#000",
                      background: "none",
                      fontWeight: "bold",
                      width: "45%",
                      border: "1px solid #d2d2d",
                      borderColor: "#d2d2d2",
                      borderRadius: "0.5rem"
                    }}
                    onClick={() => setBotModal(false)}
                  />
                  <button
                    type="button"
                    onClick={handleSave}
                    css={CheckButton}
                    style={{ width: "45%", margin: 0 }}
                    disabled={buttonSaveDisabled}
                  >
                    {loading ? (
                      <ProgressSpinner
                        style={{
                          width: "1.8rem",
                          height: "1.8rem",
                          margin: "0 0.5rem"
                        }}
                      />
                    ) : (
                      <MyImage src={Check} alt="Check-Button" />
                    )}
                    <div style={{ fontWeight: "bold" }}>Salvar</div>
                  </button>
                </div>
              </div>
            </DataBotTransfer>
          </BotTransfer>
        ) : null}
        <div className="relative h-full w-full">
          <Toast
            ref={toast}
            style={{ position: "sticky", top: 0, zIndex: 15 }}
          />
          <div className="flex align-items-center justify-content-end">
            <Button
              style={{ width: "2rem", height: "2rem", marginLeft: "auto" }}
              onClick={() => dispatch(toggleTransferModal())}
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-text p-button-sm ml-auto"
              aria-label="Cancel"
            />
          </div>
          {transferType.external === false &&
          transferType.human === false &&
          addingMoreTransferOptions ? (
            <>
              <div className="flex align-items-center">
                <Text
                  style={{ textAlign: "left" }}
                  s="0.9375rem"
                  weight={600}
                  lh="1.3125rem"
                  color="#0A043C"
                >
                  Deseja transferir o seu atendimento?
                </Text>
                <Span>?</Span>
              </div>
              <div className="flex mt-2 w-full justify-content-start align-items-start">
                <Text
                  style={{ textAlign: "left" }}
                  s="0.875rem"
                  weight={400}
                  lh="1.3125rem"
                  color="#4C5862"
                >
                  Você pode transferir esse atendimento de diversas formas,
                  escolha uma abaixo:
                </Text>
              </div>
            </>
          ) : null}
          {transferType.external === false && transferType.human === true ? (
            <>
              <div className="flex align-items-center justify-content-center">
                <Text
                  style={{ textAlign: "left" }}
                  s="0.9375rem"
                  weight={600}
                  lh="1.3125rem"
                  color="#0A043C"
                >
                  Transferir atendimento?
                </Text>
                <Span>?</Span>
              </div>
              <div className="flex mt-2 w-full flex-column justify-content-center align-items-center">
                <Text s="0.875rem" weight={400} lh="1.3125rem" color="#4C5862">
                  Informe como deseja distribuir as{" "}
                </Text>
                <Text s="0.875rem" weight={400} lh="1.3125rem" color="#4C5862">
                  oportunidades desse atendimento.
                </Text>
              </div>
              <div className="mt-4" />
            </>
          ) : null}
          <ContainerContent>
            {transferType.human && !addingMoreTransferOptions ? (
              <>
                <Dropdown
                  style={{ width: "100%" }}
                  value={selectedWorkspaceOnWebhook}
                  options={typeWorkspaceOnWebhook}
                  onChange={(e) => handleTypeWorkspaceOnWebhook(e)}
                  optionLabel="name"
                  placeholder="Workspace via Webhook"
                />
                {selectedWorkspaceOnWebhook.id === 1 ? (
                  <Dropdown
                    style={{ width: "100%", marginTop: "1rem" }}
                    value={selectedWorkspace}
                    options={workspaces}
                    onChange={(e) => handleSelectedWorkspace(e)}
                    optionLabel="name"
                    placeholder="Selecione um workspace"
                    filter={true}
                  />
                ) : null}
                {
                  // eslint-disable-next-line no-negated-condition
                  selectedWorkspace.id > 0 &&
                  selectedWorkspaceOnWebhook.id === 1 ? (
                    <Dropdown
                      style={{
                        width: "100%",
                        marginTop: "1rem",
                        lineHeight: "1.3125rem"
                      }}
                      value={selectedTypeTransfer}
                      options={typeTransfer}
                      onChange={(e) => handleSelectedTypeTransfer(e)}
                      optionLabel="name"
                      placeholder="Selecione o tipo de transferência"
                      // valueTemplate={selectedStateTemplate}
                      // itemTemplate={stateOptionTemplate}
                    />
                  ) : null
                }
              </>
            ) : (
              <div
                style={{
                  position: "relative",
                  width: "100%"
                }}
              >
                {!addingMoreTransferOptions ||
                (addingMoreTransferOptions && !transferedOmnichannel) ? (
                  <ContainerButton
                    onClick={() => {
                      setTransferType({
                        human: true,
                        bot: transferType.bot,
                        external: transferType.external
                      });
                      dispatch(setAddMoreTransferOptions(false));
                    }}
                  >
                    <BlueBar />
                    <IconButton>
                      <img src={Humans} alt="Human Icon" />
                    </IconButton>
                    <div>Atendimento Humano/Workspace</div>
                  </ContainerButton>
                ) : null}
                {!addingMoreTransferOptions ||
                (addingMoreTransferOptions && !nodeSelected?.data.transfer) ? (
                  <ContainerButton
                    onClick={() => {
                      setTransferType({
                        human: transferType.human,
                        bot: true,
                        external: transferType.external
                      });
                      setBotModal(true);
                      dispatch(setAddMoreTransferOptions(false));
                    }}
                  >
                    <BlueBar />
                    <IconButton>
                      <img src={BotBlue} alt="Bot Blue Icon" />
                    </IconButton>
                    <div>Transferir para outro robô</div>
                  </ContainerButton>
                ) : null}

                {!addingMoreTransferOptions ||
                (addingMoreTransferOptions && !transferedWebhook) ? (
                  <ContainerButton onClick={handleSetExternal}>
                    <BlueBar />
                    <IconButton>
                      <img src={Automation} alt="Automation Icon" />
                    </IconButton>
                    <div>Plataforma Externa (Webhook)</div>
                  </ContainerButton>
                ) : null}
              </div>
            )}
            {transferType.human &&
            selectedWorkspace.id > 0 &&
            selectedTypeTransfer.id === 1 &&
            selectedWorkspaceOnWebhook.id === 1 &&
            !addingMoreTransferOptions ? (
              <Dropdown
                style={{
                  width: "100%",
                  marginTop: "1rem",
                  lineHeight: "1.3125rem"
                }}
                value={selectedUser}
                options={usersFromWorksPaceSelected}
                onChange={(e) => setSelectedUser(e.value)}
                optionLabel="name"
                placeholder="Selecione um colaborador"
                filter={true}
                // valueTemplate={selectedStateTemplate}
                // itemTemplate={stateOptionTemplate}
              />
            ) : null}

            {/* {transferType.human ? (
              <div className="w-full mt-3 flex align-items-center justify-content-center">
                <div>
                  <Checkbox
                    inputId="cb1"
                    onChange={(e) => setJustOnlineUsers(e.checked)}
                    checked={justOnlineUsers}
                  />
                  <LabelBlue htmlFor="cb1" className="p-checkbox-label ml-1">
                    Somente para agentes online
                  </LabelBlue>
                </div>
              </div>
            ) : null} */}
            {!addingMoreTransferOptions &&
            transferType.external === false &&
            transferType.human === true ? (
              <BoxAgentNotice>
                <i className="pi pi-info-circle" />
                <Text
                  style={{ textAlign: "left" }}
                  s="0.875rem"
                  lh="1.125rem"
                  color="#000000"
                >
                  Cada agente vai receber a oportunidade de forma sequencial de
                  1 para 1.
                </Text>
              </BoxAgentNotice>
            ) : null}
          </ContainerContent>
          <Divisor />

          {!addingMoreTransferOptions &&
          transferType.external === false &&
          transferType.human === true ? (
            <ContainerButtonEnd>
              <CancelButton onClick={() => dispatch(toggleTransferModal())}>
                CANCELAR
              </CancelButton>
              {/* Arrumar para o disabled funcionar corretamente. */}
              <SaveButton
                disabled={
                  selectedWorkspaceOnWebhook.id === -1 ||
                  (selectedWorkspaceOnWebhook.id === 1 &&
                    selectedWorkspace.id < 1) ||
                  (selectedWorkspaceOnWebhook.id === 1 &&
                    selectedWorkspace.id !== -1 &&
                    selectedTypeTransfer.id === -1) ||
                  (selectedWorkspaceOnWebhook.id === 1 &&
                    selectedWorkspace.id > 0 &&
                    selectedTypeTransfer.id === 1 &&
                    selectedUser.id === -1)
                }
                onClick={handleAskSave}
              >
                {/* Arrumar para o disabled funcionar corretamente. */}
                <MyImage
                  disabled={
                    selectedWorkspaceOnWebhook.id === -1 ||
                    (selectedWorkspaceOnWebhook.id === 1 &&
                      selectedWorkspace.id < 1) ||
                    (selectedWorkspaceOnWebhook.id === 1 &&
                      selectedWorkspace.id !== -1 &&
                      selectedTypeTransfer.id === -1) ||
                    (selectedWorkspaceOnWebhook.id === 1 &&
                      selectedWorkspace.id > 0 &&
                      selectedTypeTransfer.id === 1 &&
                      selectedUser.id === -1)
                  }
                  src={Check}
                  alt="Check-Button"
                />
                Salvar Informações
              </SaveButton>
            </ContainerButtonEnd>
          ) : null}
        </div>
      </dialog>
    </>
  );
};
