import styled from "@emotion/styled";

export const Text = styled.div`
  strong {
    font-weight: bold;
    display: inline;
  }

  ul {
    list-style-type: disc;
    padding-left: 40px;
  }
`;

export const TextBold = styled.div`
  display: flex;
  font-weight: bold;
`;

export const Image = styled.div`
  margin: 20px 0;
`;
