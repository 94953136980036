import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { JsonObject } from "react-use-websocket/dist/lib/types";
import { AuthContextType, useAuth } from "../../auth/auth-provider";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSidebarChats } from "../../redux/slices/sidebar";
import useOmnichannel, {
  FindBotsList,
  FindDialogOutput,
  FindDialogsOutput
} from "../use-omnichannel";
import Dialogs from "./dialogs";
import FiltersBar from "./filters-bar";
import IdentificationBar from "./identification-bar";
import SearchBarCounter from "./search-bar-counter";
import WorkspaceBar from "./workspace-bar";
import { soundNotification } from "../../assets";

export interface SidebarProps {
  shouldUpdate: boolean;
  onShouldUpdateChange: () => void;
  dialog?: FindDialogOutput;
  onDialogClick: (id: number) => void;
}

export interface BotListDropdown {
  label: string;
  value: number;
  disabled?: boolean;
}

export interface ChatMessageDialogJson extends JsonObject {
  id: number;
  name: string;
  lastMessageDateTime: string;
  lastMessage: string;
  unreadMessageTotal: number;
  plataform: string;
}

export interface ChatMessageJson extends JsonObject {
  unreadRooms: number;
  dialogs: ChatMessageDialogJson[];
}

const Sidebar: React.FC<SidebarProps> = ({
  shouldUpdate,
  onShouldUpdateChange,
  dialog,
  onDialogClick
}: SidebarProps) => {
  const { user } = useAuth() as AuthContextType;
  const location = useLocation();

  const { findBotsList } = useOmnichannel();

  const { findDialogsPageable, findUnreadRooms, markAsRead } = useOmnichannel();

  const [workspace, setWorkspace] = useState(0);
  const [status, setStatus] = useState("OPEN");
  const [bot, setBot] = useState(0);
  const [search, setSearch] = useState("");
  const [botsList, setBotsList] = useState<FindBotsList[]>();
  const [botsListForDropdown, setBotsListForDropdown] =
    useState<BotListDropdown[]>();

  const [dialogs, setDialogs] = useState<FindDialogsOutput[]>([]);
  const [unreadRooms, setUnreadRooms] = useState(0);

  const [hasRequest, setHasRequest] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const limit = window.innerHeight > 1000 ? 16 : 10;
  const [offset, setOffset] = useState(0);

  const { lastJsonMessage } = useWebSocket<ChatMessageJson>(
    process.env.NODE_ENV === "development"
      ? `ws://${process.env.OMNICHANNEL_URL}/omnichannel-sidebar/${user.id}`
      : `wss://${process.env.OMNICHANNEL_URL}/omnichannel-sidebar/${user.id}`,
    {
      retryOnError: true,
      reconnectAttempts: 10,
      reconnectInterval: 5_000,
      heartbeat: {
        interval: 10_000,
        message: `{ "ping": true }`,
        returnMessage: "pong",
        timeout: 60_000
      }
    }
  );

  const [screenMobile, setScreenMobile] = useState(false);
  const sidebarChats = useAppSelector((state) => state.sidebar.sidebarChats);
  const lastMessageSent = useAppSelector(
    (state) => state.sidebar.lastMessageSent
  );
  const dispatch = useAppDispatch();

  const [lastId, setLastId] = useState(0);

  const setFirstDialog = async (dialogsArray: Array<any>) => {
    if (!location?.state?.dialogId) {
      const firstDialogId = dialogsArray[0]?.id;

      if (firstDialogId) {
        await markAsRead(firstDialogId);
        setLastId(firstDialogId);
        onDialogClick(firstDialogId);
      }
    }
  };

  const getBotsList = async () => {
    const bots = await findBotsList({
      companyId: user.companyId,
      workspace
    });

    setBotsList(bots.result);
  };

  const getBotsListForDropdown = () => {
    // eslint-disable-next-line no-negated-condition
    if (!botsList?.length) {
      const emptyBotList: BotListDropdown = {
        label: "Nenhum bot encontrado para este workspace",
        value: 0,
        disabled: true
      };

      setBotsListForDropdown([emptyBotList]);
    } else {
      const botList: BotListDropdown[] = [];

      botsList.forEach((element) => {
        const bot: BotListDropdown = {
          label: element.name,
          value: element.id
        };

        botList.push(bot);
      });

      setBotsListForDropdown(botList);
    }
  };

  const fetchData = async (keepdata?: boolean) => {
    if (hasRequest) {
      return;
    }

    setHasRequest(true);

    const response = await findDialogsPageable({
      workspace,
      bot,
      name: search,
      status,
      limit,
      offset: keepdata ? offset : 0
    });

    setTotalCount(response.total);

    if (keepdata) {
      const concatData = [...dialogs, ...response.result];

      setDialogs(concatData);
    } else {
      setDialogs(response.result);
      setOffset(0);
    }

    const unreadRooms = await findUnreadRooms({
      workspace,
      name: search,
      status
    });

    setUnreadRooms(unreadRooms);

    setHasRequest(false);
  };

  const fetchMoreDialogsViaInfiniteScroll = () => {
    if (totalCount > offset) {
      setOffset(offset + limit);
    }
  };

  useEffect(() => {
    const run = async () => {
      setHasRequest(true);

      const response = await findDialogsPageable({
        workspace,
        bot,
        name: search,
        status,
        limit,
        offset
      });

      setTotalCount(response.total);

      const dialogs = response.result;

      setDialogs(dialogs);

      if (dialogs.length > 0) {
        setFirstDialog(dialogs);
      }

      const unreadRooms = await findUnreadRooms({
        workspace,
        name: search,
        status
      });

      setUnreadRooms(unreadRooms);

      getBotsList();
      setHasRequest(false);
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBotsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace]);

  useEffect(() => {
    getBotsListForDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botsList]);

  const requestNotificationPermission = async () => {
    if ("Notification" in window) {
      const permission = await Notification.requestPermission();
      return permission === "granted";
    }
    return false;
  };

  const sendNotification = (message: string) => {
    if (Notification.permission === "granted") {
      new Notification("Nova Mensagem", {
        body: message
      });
    }
  };

  useEffect(() => {
    const initNotifications = async () => {
      await requestNotificationPermission();
    };

    initNotifications();
  }, []);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      setDialogs(lastJsonMessage.dialogs);
      setUnreadRooms(lastJsonMessage.unreadRooms);

      const newMessages = lastJsonMessage.dialogs.some(
        (dialog) => dialog.unreadMessageTotal > 0
      );

      const currentDialog = lastJsonMessage.dialogs.find(
        (dialog) => dialog.id === lastId
      );

      const verifyLastMessage = currentDialog?.lastMessage;

      const tabVisible = !document.hidden;

      if (
        newMessages &&
        verifyLastMessage !== lastMessageSent &&
        verifyLastMessage != null &&
        !tabVisible
      ) {
        const playNotificationSound = () => {
          const audio = new Audio(soundNotification);
          audio.play();
        };

        playNotificationSound();
        sendNotification("Você possui uma nova mensagem");
      }
      if (currentDialog && currentDialog.unreadMessageTotal > 0) {
        setDialogs((prevDialogs) =>
          prevDialogs.map((dialog) =>
            dialog.id === currentDialog.id
              ? { ...dialog, unreadMessageTotal: 0 }
              : dialog
          )
        );
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    const run = async () => {
      if (shouldUpdate) {
        await fetchData();

        onShouldUpdateChange();
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, bot, search, workspace]);

  useEffect(() => {
    fetchData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setScreenMobile(true);
      } else {
        setScreenMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (screenMobile) {
    if (sidebarChats) {
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            position: relative;
            height: 100%;
            width: 100vw;
            border-radius: 0;
            background-color: #2f343d;
            overflow: hidden;
            color: #90a0b7;
          `}
        >
          <IdentificationBar />

          <WorkspaceBar
            workspace={workspace}
            onWorkspaceChange={(workspace) => setWorkspace(workspace)}
            onRefreshClick={async () => {
              await fetchData();
            }}
          />

          <FiltersBar
            status={status}
            bot={bot}
            botsList={botsListForDropdown}
            onStatusChange={async (status) => {
              setStatus(status);
            }}
            onBotsListChange={async (bot) => {
              setBot(bot);
            }}
          />

          <SearchBarCounter
            search={search}
            unreadRooms={unreadRooms}
            onSearch={async (search) => {
              setSearch(search);
            }}
          />

          <Dialogs
            hasRequest={hasRequest}
            dialog={dialog}
            dialogs={dialogs}
            fetchMoreDialogs={fetchMoreDialogsViaInfiniteScroll}
            onDialogClick={async (id) => {
              await markAsRead(id);

              const unreadRooms = await findUnreadRooms({
                workspace,
                name: search,
                status
              });

              setUnreadRooms(unreadRooms);

              onDialogClick(id);
              dispatch(setSidebarChats(false));
            }}
          />
        </div>
      );
    }

    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        width: 300px;
        z-index: 3;
        border-radius: 0px 25px 0px 0px;
        background-color: #2f343d;
        overflow: hidden;
        color: #90a0b7;
      `}
    >
      <IdentificationBar />

      <WorkspaceBar
        workspace={workspace}
        onWorkspaceChange={(workspace) => setWorkspace(workspace)}
        onRefreshClick={async () => {
          await fetchData();
        }}
      />

      <FiltersBar
        status={status}
        bot={bot}
        botsList={botsListForDropdown}
        onStatusChange={async (status) => {
          setStatus(status);
        }}
        onBotsListChange={async (bot) => {
          setBot(bot);
        }}
      />

      <SearchBarCounter
        search={search}
        unreadRooms={unreadRooms}
        onSearch={async (search) => {
          setSearch(search);
        }}
      />

      <Dialogs
        hasRequest={hasRequest}
        dialog={dialog}
        dialogs={dialogs}
        fetchMoreDialogs={fetchMoreDialogsViaInfiniteScroll}
        onDialogClick={async (id) => {
          if (id !== lastId) {
            await markAsRead(id);

            const unreadRooms = await findUnreadRooms({
              workspace,
              name: search,
              status
            });

            setUnreadRooms(unreadRooms);
            onDialogClick(id);

            setDialogs((prevDialogs) =>
              prevDialogs.map((d) =>
                d.id === id ? { ...d, unreadMessageTotal: 0 } : d
              )
            );
          } else {
            setDialogs((prevDialogs) =>
              prevDialogs.map((d) =>
                d.id === id ? { ...d, unreadMessageTotal: 0 } : d
              )
            );
          }
          setLastId(id);
        }}
      />
    </div>
  );
};

export default Sidebar;
