import { Text } from "../../../../../components/Text/text";
import { girl, PlayRed } from "../../../../../assets";
import { PlayButton } from "./styles";

export const RightContentChatBotModal: React.FC = () => {
  return (
    <div className="text-white">
      <div className="flex align-items-center justify-content-center">
        <img src={girl} alt="Bot Girl" />
      </div>
      <div className="my-4">
        <Text
          fontSize="13px"
          fontWeight={500}
          lineHeight="16px"
          color="#FAFBFC"
        >
          Dica para integrar o seu Chatbot{" "}
        </Text>
      </div>
      <Text fontSize="11px" fontWeight={400} lineHeight="14px" color="#FAFBFC">
        Copie e cole o script gerado diretamente no código do seu site para
        integrar o nosso LiveChat em segundos. Sem complicações, com suporte
        para customizações de estilo e comportamento, garantindo uma experiência
        única para seus visitantes!
      </Text>
      <div className="flex align-items-center justify-content-start  mt-4">
        <PlayButton>
          <img
            style={{ marginLeft: "0.14rem" }}
            src={PlayRed}
            alt="Icon-Play-Video"
          />
        </PlayButton>
        <div className="ml-2">
          <Text
            fontSize="13px"
            fontWeight={500}
            lineHeight="16px"
            color="#FAFBFC"
          >
            Vídeo - Chatbot
          </Text>
        </div>
      </div>
    </div>
  );
};
