import { Position } from "react-flow-renderer";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const EntitiesStyle = css`
  background: #ffffff;
  border: 1px solid #c4ccd8;
  border-radius: 6px;
  width: 100%;
  height: 64px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #544f77;
  z-index: 10;
  overflow: hidden;
  margin-top: 1rem;
  & + & {
    margin-top: 1rem;
  }
  :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
export const EntityBlueBar = css`
  width: 0.75rem;
  height: 100%;
  background: #046af3;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
`;
export const Label = css`
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #888888;
`;

export const Options = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  background-color: #fff;
  color: #4c5862;
`;

export const Modal = css`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a043c;
  background-color: white;
  border-radius: 1rem;
  border: none;
  width: 98%;
  height: 95%;
  z-index: 7;
  position: absolute;
  top: 10px;
  left: 0;
`;
export const Overlay = css`
  position: absolute;
  z-index: 6;
  min-height: 100vh;
  max-height: max-content;
  width: calc(100% + 2rem);
  top: 0;
  left: -2rem;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const Title = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #0a043c;
  margin: 0 auto;
`;
export const Textp = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #544f77;
  text-align: left;
  /* display: -webkit-inline-box; */
  &:after {
    display: inline-flex;
    flex-direction: row;
    margin-left: 0.5rem;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    align-items: center;
    justify-content: center;
    background-color: #c5dcff;
    content: "?";
  }
`;
export const VDivisor = css`
  border: 1px solid #d2d2d2;
  width: 2.4rem;
  transform: rotate(90deg);
  position: absolute;
  top: 48%;
  right: 1.3rem;
  /* transform-origin: left top; */
`;

// export const CheckButton = css`
//   width: 150px;
//   border-radius: 10px;
// `;

export const CheckButton = css`
  position: sticky;
  bottom: 4.5%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #0075ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #fff;
  width: 100%;
  height: 4rem;
  border-radius: 0 0 1rem 1rem;
  &:disabled {
    color: #e6f1ff;
  }
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
    background-color: #cce3ff;
  }
`;

interface IMyImage {
  disabled: boolean;
}
export const MyImage = styled.img<IMyImage>`
  filter: ${(props) =>
    props.disabled
      ? "invert(92%) sepia(94%) saturate(1778%) hue-rotate(178deg) brightness(104%) contrast(111%)"
      : ""};
  // color: ${(props) => (props.disabled ? "#CCE3FF" : "#E6F1FF")};
  margin-right: 0.5rem;
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
  }
`;
export const ContainerContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 1;
  height: calc(100% - 4rem);
  max-height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
  padding-top: 5px;
`;

export const ContainerButton = styled.div`
  max-height: 12%;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
`;

export const DivInputSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
