import { ContainerLeft } from "./../../header/components/Header2/styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Span = styled.span`
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 0.875rem;
  color: #046af3;
  background-color: #d4e2f3;
  width: 1.0625rem;
  height: 1.0625rem;
  border-radius: 50%;
`;

export const Text = css`
  margin-bottom: 50%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #6d6f6f;
`;
export const TextNoMargin = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #6d6f6f;
`;

export const CheckButton = css`
  margin: 0 auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #0075ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  width: 50%;
  height: 64px;
  border: 1px solid #d2d2d2;
  border-radius: 0.5rem;
  &:disabled {
    color: #e6f1ff;
  }
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
    background-color: #cce3ff;
  }
`;
export const ContainerTab = styled.div`
  height: 100%;
  padding: 0;
`;
export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
`;
export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-bottom: 2rem;
`;

export const ContainerInside = styled.div`
  margin-top: 4rem;
  width: 100%;
  overflow-y: scroll;
  z-index: 1;
`;
export const Container = styled.div`
  max-height: calc(-72px + 100vh);
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0.5rem;
  background-color: #fff;
  height: 100%;
  margin-bottom: 1rem;
`;
export const ContainerLeftModal = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: white;
  padding: 1rem;
  padding-top: 0rem;
`;
export const ContainerRightModal = styled.div`
  width: 68.3%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: #ff0025;
  padding: 1rem;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;
export const Button = styled.button`
  height: 28px;
  width: 250px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  color: #dddddd;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  color: #333c3f;
  :hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: #dddddd;
    color: #ffffff;
  }
`;
export const Transfer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  padding: 0.5rem;
  margin: 0.5rem auto;
  & + & {
    margin-bottom: 1.5rem;
  }
`;
export const TransferAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4rem;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  padding: 0.5rem;
  margin: 0.5rem auto 1.5rem auto;
  gap: 0.5rem;
  :hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: #dddddd;
    color: #ffffff;
  }
`;
export const CardrLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 0.5rem;
`;
export const CardRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-right: 1rem;
`;

export const ButtonRoundedBigBlue = styled.button`
  width: 33px;
  height: 33px;
  display: flex;
  padding-bottom: 2px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 1rem;
  border: none;
  background-color: #d4e2f3;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    background-color: #d2d2d2;
    transition: all ease-in-out 400ms;
  }
`;

export const ButtonRoundedBigRed = styled.button`
  width: 33px;
  height: 33px;
  display: flex;
  padding-bottom: 2px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #ffe8e2;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    background-color: #d2d2d2;
    transition: all ease-in-out 400ms;
  }
`;
export const ContainerIcon = styled.div`
  width: 1.6875rem;
  height: 1.6875rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c2cfe0;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #046af3;
`;

export const BackgrounIcon = styled.div`
  background-color: #d4e2f3;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  min-height: 1.5rem;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;
