import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setModalTutorial } from "../../../../redux/slices/flowInteractions";
import { Dialog } from "primereact/dialog";
import { Text, TextBold, Image } from "./styles";
import {
  DialogFlow1,
  DialogFlow2,
  DialogFlow3,
  DialogFlow4,
  DialogFlow5,
  DialogFlow6,
  DialogFlow7,
  DialogFlow8,
  DialogFlow9,
  DialogFlow10,
  DialogFlow11,
  DialogFlow12,
  DialogFlow13,
  DialogFlow14,
  DialogFlow15,
  DialogFlow16,
  DialogFlow17,
  DialogFlow18
} from "../../../../assets";
import { Divider } from "primereact/divider";

const CredentialsTutorial = () => {
  const dispatch = useAppDispatch();
  const modalTutorial = useAppSelector(
    (state) => state.flowInteractions.modalTutorial
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const modalWidth = screenWidth <= 1400 ? "95vw" : "85vw";

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Dialog
      visible={modalTutorial}
      onHide={() => dispatch(setModalTutorial(false))}
      draggable={false}
      resizable={false}
      header="Tutorial de obtenção das credenciais do Dialogflow Essentials"
      headerStyle={{
        textAlign: "center",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px"
      }}
      contentStyle={{
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px"
      }}
      style={{
        width: modalWidth,
        border: "none"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          padding: "5px 15px"
        }}
      >
        <Text>
          Parabéns! Você está prestes a criar seu robô, para que isso seja
          possível, precisamos antes obter a chave que vai conectar seu robô à
          inteligência artificial que faz toda a mágica acontecer!
        </Text>
        <Text>Vamos lá!</Text>
        <Divider
          style={{
            margin: "10px 0",
            backgroundColor: "#b2b2b2",
            height: "1px"
          }}
        />
        <TextBold>Passo 1 – Acessando o Console</TextBold>
        <Text>No seu navegador, esteja logado com uma conta Google.</Text>
        <Image>
          <img src={DialogFlow1} style={{ width: "100%" }} />
        </Image>
        <Text>
          Estando logado com uma conta Google, acesse o endereço do DialogFlow
          ES através do endereço:{" "}
          <a href="https://dialogflow.cloud.google.com/" target="_blank">
            https://dialogflow.cloud.google.com/
          </a>
          .
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow2} style={{ width: "30%" }} />
        </Image>
        <Text>
          Clique em&nbsp;<strong>"Sign-In with Google"</strong>
        </Text>
        <Text>
          Você será direcionado para o Console do DialogFlow, no primeiro botão
          do menu da esquerda, clique em&nbsp;<strong>"+Create Agente"</strong>.
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow3} style={{ width: "50%" }} />
        </Image>
        <Divider
          style={{
            margin: "10px 0",
            backgroundColor: "#b2b2b2",
            height: "1px"
          }}
        />
        <TextBold>Passo 2 - Criando seu Agente</TextBold>
        <Text>
          <ul>
            <li>
              Preencha o campo&nbsp;<strong>“Agent Name”</strong>: Utilize o
              nome de sua preferência, mas&nbsp;<strong>sem espaços.</strong>
            </li>
            <li>
              Selecione o idioma de sua preferência em&nbsp;
              <strong>“Default Language”</strong>: O sistema carrega por padrão
              English US, altere para Portuguese (Brazilian) – pt br.
            </li>
            <li>
              Selecione o fuso horário em&nbsp;
              <strong>“Default Time Zone”</strong>: O sistema traz por padrão
              (GMT -3:00) America/Buenos_Aires. Mantenha este se o seu fuso for
              o horário de Brasília, se não, altere.
            </li>
          </ul>
        </Text>
        <Text>
          E Clique em&nbsp;<strong>“Create”</strong>&nbsp;para finalizar.
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow4} style={{ width: "50%" }} />
        </Image>
        <Divider
          style={{
            margin: "10px 0",
            backgroundColor: "#b2b2b2",
            height: "1px"
          }}
        />
        <TextBold>Passo 3 - Criando sua conta de serviço.</TextBold>
        <Text>
          Você será direcionado a tela de alteração do seu agente. No menu
          lateral a esquerda, será exibido o nome do seu agente e ao lado, um
          ícone de engrenagem.
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow5} style={{ width: "60%" }} />
        </Image>
        <Text>Clique na Engrenagem.</Text>
        <Text>
          Você será direcionado para a tela de configurações do agente. Nela
          localize o campo&nbsp;<strong>“Project ID”</strong>, e clique no
          código em azul neste campo.
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow6} style={{ width: "70%" }} />
        </Image>
        <Text>
          Você será direcionado para outro site, o Console Google Cloud. Caso
          seja seu primeiro acesso, e o sistema solicitar, aceite os termos de
          uso, para seguir em frente.
        </Text>
        <Text>
          No menu lateral, passe o cursor sobre a opção&nbsp;
          <strong>“IAM e administrador"</strong>, será exibido um submenu, nele
          selecione a opção&nbsp;<strong>"Contas de Serviço"</strong>.
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow7} style={{ width: "70%" }} />
        </Image>
        <Text>
          Você será direcionado para a listagem de contas de serviço deste
          projeto. Ela estará vazia. Clique no botão&nbsp;
          <strong>"+Criar conta de serviço”.</strong>
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow8} style={{ width: "70%" }} />
        </Image>
        <Text>
          No cadastro da conta de serviço, existem 3 seções de informações, o
          sistema te encaminhará direto a primeira seção: Detalhes da Conta de
          serviço. Nela preencha&nbsp;
          <strong>“Nome da conta de serviço”</strong>. E clique em&nbsp;
          <strong>“Criar e continuar”</strong>.
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow9} style={{ width: "60%" }} />
        </Image>
        <Text>
          Na sequência, na seção 2 definiremos os acessos desta conta de
          serviço. Preencha o campo&nbsp;<strong>“Selecione papel”</strong>,
          busque pela opção&nbsp;
          <strong>“Administrator da Dialogflow API”</strong>, e selecione ela.
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow10} style={{ width: "60%" }} />
        </Image>
        <Text>
          Então clique em&nbsp;<strong>"Continuar"</strong>.
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow11} style={{ width: "40%" }} />
        </Image>
        <Text>
          Na seção 3 não é necessário fazer nenhuma alteração. Então clique
          em&nbsp;<strong>“Concluir”.</strong>
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow12} style={{ width: "40%" }} />
        </Image>
        <Divider
          style={{
            margin: "10px 0",
            backgroundColor: "#b2b2b2",
            height: "1px"
          }}
        />
        <TextBold>Passo 4 - Obtendo sua Chave de API</TextBold>
        <Text>
          Após finalizar a criação da conta de serviço, você será direcionado de
          volta a tela de listagem das contas. Clique sobre o campo&nbsp;
          <strong>“E-mail”</strong>&nbsp;da sua conta.
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow13} style={{ width: "60%" }} />
        </Image>
        <Text>
          O sistema vai lhe direcionar a administração da sua conta. Clique na
          opção&nbsp;<strong>“Chaves”</strong>, ou &nbsp;
          <strong>“Keys”.</strong>
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow14} style={{ width: "60%" }} />
        </Image>
        <Text>
          Você será direcionado para a listagem de chaves da sua conta. Nesta
          tela clique em&nbsp;<strong>“Adicionar chave”</strong>, depois clique
          em&nbsp;<strong>“Criar nova chave”</strong>, e selecione a opção&nbsp;
          <strong>“JSON”</strong>. Clique em&nbsp;
          <strong>“Criar”.</strong>
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow15} style={{ width: "40%" }} />
        </Image>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow16} style={{ width: "40%" }} />
        </Image>
        <Text>
          Será feito o download de um arquivo&nbsp;<strong>.json</strong> no seu
          computador
        </Text>
        <Divider
          style={{
            margin: "10px 0",
            backgroundColor: "#b2b2b2",
            height: "1px"
          }}
        />
        <TextBold>Passo 5 - Inserindo sua chave no sistema</TextBold>
        <Text>
          No sistema, em Configurações {" > "} Credenciais, clique em&nbsp;
          <strong>“+ Gerar Chave”.</strong> O sistema irá exibir uma nova tela,
          clique em&nbsp;<strong>“Upload”.</strong>
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow17} style={{ width: "70%" }} />
        </Image>
        <Text>
          O sistema abrirá o navegador de arquivos do seu computador ou celular.
          Localize e selecione o arquivo&nbsp;<strong>.json</strong> que foi
          baixado no passo anterior.
        </Text>
        <Image
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img src={DialogFlow18} style={{ width: "70%" }} />
        </Image>
        <Text>
          Depois clique em&nbsp;<strong>"Adicionar"</strong>.
        </Text>
        <Text>
          E pronto! Sua chave de API foi instalada no sistema, agora você já
          pode dar inicio a criação do seu Robô!
        </Text>
        <Text>
          Lembrando que,&nbsp;<strong>um</strong> robô utiliza&nbsp;
          <strong>duas</strong> chaves de API, uma para testes, e outra para
          produção, portanto será necessário repetir este processo inteiro
          (passos, 1 ao 5) duas vezes.
        </Text>
      </div>
    </Dialog>
  );
};

export default CredentialsTutorial;
