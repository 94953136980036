/* eslint-disable no-nested-ternary */
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  setCustomEntityUpdating,
  setEntityIdUpdate,
  setInsertEntity,
  setUpdatedEntities,
  toggleOpenEntityDialog,
  clearEntities,
  setUpdateDataTable
} from "../../../../redux/slices/flowInteractions";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import {
  CheckButton,
  ContainerButton,
  ContainerContent,
  DivInputSwitch,
  MyIcon,
  MyImage,
  Textp,
  Title,
  VDivisor
} from "./styles";
import { Check } from "../../../../assets";
import { EntityTypesDB } from "../../../../types/entityTypes";
import entityTypesServices from "../../../../services/entityTypesService";
import { IResponseGetChatbots } from "../../../../types/chatbots";
import BotsServices from "../../../../services/bots";
import { Dropdown, useToast } from "../../..";
import { ProgressSpinner } from "primereact/progressspinner";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";

interface ICustomEntities {
  onClick?: () => void;
}
interface INewEntitiesValues {
  id: number;
  value: string;
}
// const envio = {
//   name: "@Estados",
//   defineSynonyms: true,
//   regexpEntity: true,
//   allowAutomatedExpansion: true,
//   fuzzyMatching: true,
//   entries: [
//     { id: 1, value: "Pernambuco", synonyms: ["PE", "Pernambuco"] },
//     { id: 2, value: "Sergipe", synonyms: ["Sergipe", "SE"] },
//     { id: 3, value: "Tocantins", synonyms: ["TO", "Tocantins"] },
//     { id: 4, value: "Piauí", synonyms: ["PI", "Piauí"] },
//     { id: 5, value: "Santa Catarina", synonyms: ["SC", "Santa Catarina"] },
//     { id: 6, value: "Distrito Federal", synonyms: ["DF", "Distrito Federal"] },
//     { id: 7, value: "Acre", synonyms: ["AC", "Acre"] },
//     { id: 8, value: "Rio de Janeiro", synonyms: ["Rio de Janeiro", "RJ"] },
//     { id: 9, value: "Roraima", synonyms: ["RR", "Roraima"] },
//     { id: 10, value: "Amazonas", synonyms: ["AM", "Amazonas"] },
//     { id: 11, value: "Mato Grosso", synonyms: ["Mato Grosso", "MT"] },
//     { id: 12, value: "São Paulo", synonyms: ["SP", "São Paulo", "Sampa"] },
//     { id: 13, value: "Paraná", synonyms: ["PR", "Paraná"] },
//     { id: 14, value: "Rondônia", synonyms: ["RO", "Rondônia"] },
//     { id: 15, value: "Maranhão", synonyms: ["MA", "Maranhão"] },
//     { id: 16, value: "Minas Gerais", synonyms: ["Minas Gerais", "MG"] },
//     { id: 17, value: "Amapá", synonyms: ["Amapá", "AP"] },
//     { id: 18, value: "Paraíba", synonyms: ["PB", "Paraíba"] },
//     { id: 19, value: "Espírito Santo", synonyms: ["ES", "Espírito Santo"] },
//     { id: 20, value: "Alagoas", synonyms: ["Alagoas", "AL"] },
//     {
//       id: 21,
//       value: "Rio Grande do Norte",
//       synonyms: ["RN", "Rio Grande do Norte"]
//     },
//     { id: 22, value: "Goiás", synonyms: ["Goiás", "GO"] },
//     { id: 23, value: "Bahia", synonyms: ["BA", "Bahia"] },
//     { id: 24, value: "Ceará", synonyms: ["CE", "Ceará"] },
//     { id: 25, value: "Pará", synonyms: ["Pará", "PA"] },
//     {
//       id: 26,
//       value: "Mato Grosso do Sul",
//       synonyms: ["MS", "Mato Grosso do Sul"]
//     },
//     {
//       id: 27,
//       value: "Rio Grande do Sul",
//       synonyms: ["RS", "Rio Grande do Sul"]
//     }
//   ],
//   botId: 1
// };
export const CustomEntities: React.FC<ICustomEntities> = ({ onClick }) => {
  const dispatch = useAppDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [NewEntityName, setNewEntityName] = useState("");
  const [inputEdit, setInputEdit] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const botId = useAppSelector((state) => state.flowInteractions.botIdActive);
  const importEntities = useAppSelector(
    (state) => state.flowInteractions.customEntities
  );
  const [newEntiteValues, setNewEntiteValues] = useState<INewEntitiesValues[]>(
    []
  );
  const [filteredEntiteValues, setFilteredNewEntiteValues] = useState<
    INewEntitiesValues[]
  >([]);
  const [notFound, setNotFound] = useState(false);
  const toast = useToast();
  const { insertEntities, updateEntities, getEntitiesById } =
    entityTypesServices();
  const entityUpdating = useAppSelector(
    (state) => state.flowInteractions.customEntityUpdating
  );
  const selectedWorksPaces = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );

  const entityIdUpdate = useAppSelector(
    (state) => state.flowInteractions.entityIDUpdate
  );
  const { user, company } = useAuth() as AuthContextType;
  const companyId = company?.id;
  const companyIdSelected = user.admin ? companyId : user.companyId;

  const [fuzzyMatching, setFuzzyMatching] = useState(false);
  const [regexpEntity, setRegexpEntity] = useState(false);

  // const customModalOpen = useAppSelector(
  //   (state) => state.flowInteractions.customEntityModalOpen
  // );
  // const [chatbots, setChatbots] = useState<IResponseGetChatbots>(
  //   {} as IResponseGetChatbots
  // );

  const [botSelected, setBotSelected] = useState(-1);
  const {
    // GetBots,
    ListBotsByWorksPaces
  } = BotsServices();
  useEffect(() => {
    const GetChatbots = async () => {
      try {
        setLoading(true);
        const results = await ListBotsByWorksPaces({
          limit: 10,
          offset: 0,
          workspaces: selectedWorksPaces,
          companyId: companyIdSelected
        });
        setLoading(false);
        // setChatbots(results);

        const dropdownoptions = results.result.map(
          (bot: { name: string; id: number }) => {
            return {
              label: bot.name,
              value: bot.id
            };
          }
        );
        setOptions(dropdownoptions);
      } catch (error) {
        setLoading(false);
        // console.error(error);
        toast.current?.show({
          life: 3000,
          severity: "error",
          summary: "Lista de bots indisponível.",
          detail: "Não foi possível buscar a lista de bots neste momento."
        });
      }
    };
    GetChatbots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const handleNewEntitiesValues = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setInputEdit(e.target.value);
  // };
  const handleNewEntitiesKeypress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setNewEntiteValues((oldState) => [
        ...oldState,
        {
          id: new Date().getTime(), // Gere um ID único baseado no timestamp
          value: inputEdit
        }
      ]);
      setInputEdit("");
    }
  };

  useEffect(() => {
    const updatedEntity = async () => {
      if (entityIdUpdate !== -1 && entityUpdating) {
        setLoading(true);
        const values = await getEntitiesById(entityIdUpdate);
        setNewEntityName(values.displayName);
        setFuzzyMatching(values.fuzzyMatching);
        setRegexpEntity(values.regexpEntity);
        const entityValues = values.entities.map(
          (el: { id: number; value: string; synonyms: string[] }) => {
            return {
              id: el.id,
              value: [el.value, ...el.synonyms].join().replaceAll(",", ", ")
            };
          }
        );
        setLoading(false);
        setNewEntiteValues(entityValues);
      } else {
        setSearchInput("");
        setNewEntityName("");
        setNewEntiteValues([]);
        setFilteredNewEntiteValues([]);
      }
    };
    updatedEntity();
    dispatch(setUpdatedEntities());

    return () => {
      dispatch(setEntityIdUpdate(-1));
      dispatch(setCustomEntityUpdating(false));
      dispatch(setInsertEntity(false));
      dispatch(clearEntities());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityIdUpdate, entityUpdating]);

  useEffect(() => {
    if (importEntities && importEntities.length > 0) {
      const importedValues = importEntities.map((entity, index) => ({
        id: new Date().getTime() + index,
        value: entity.join(", ")
      }));
      setNewEntiteValues((oldState) => [...oldState, ...importedValues]);
    }
  }, [importEntities]);

  const handleSalvar = async () => {
    setLoading(true);

    if (!NewEntityName?.length) {
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Nome da entidade invalido.",
        detail: "Favor inserir um nome para sua entidade.",
        sticky: true
      });
      setLoading(false);
    }
    if (!newEntiteValues?.length) {
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Entidade inválida.",
        detail:
          "Favor inserir pelo menos um valor/sinônimo para a nova entidade.",
        sticky: true
      });
      setLoading(false);
    }
    if (NewEntityName?.length && newEntiteValues?.length) {
      const saveObject: EntityTypesDB = {
        name: NewEntityName.replaceAll(" ", "_"),
        defineSynonyms: regexpEntity ? false : true,
        regexpEntity: regexpEntity,
        allowAutomatedExpansion: false,
        fuzzyMatching: fuzzyMatching,
        entries: newEntiteValues.map((newEntityValue, idx) => {
          if (regexpEntity) {
            return {
              id: idx + 1,
              value: newEntityValue.value.trim(),
              synonyms: []
            };
          } else {
            const synonymsSplit = newEntityValue.value.split(",");
            const synonymsSplitWithoutSpaces = synonymsSplit.map((el) =>
              el.trim()
            );
            return {
              id: idx + 1,
              value: synonymsSplitWithoutSpaces[0],
              synonyms: synonymsSplitWithoutSpaces.slice(1)
            };
          }
        }),
        botId: entityUpdating ? botId : botSelected
      };
      try {
        if (entityUpdating) {
          await updateEntities(entityIdUpdate, saveObject);
          setLoading(false);
          dispatch(toggleOpenEntityDialog());
          dispatch(setUpdatedEntities());
          dispatch(setCustomEntityUpdating(false));
        } else {
          await insertEntities(saveObject);
          setLoading(false);
          dispatch(setEntityIdUpdate(-1));
          dispatch(setCustomEntityUpdating(false));
          dispatch(setInsertEntity(false));
          dispatch(setUpdatedEntities());
          dispatch(toggleOpenEntityDialog());
        }
        toast?.current?.show({
          severity: "success",
          detail: "Entidade salva com sucesso ."
        });
      } catch (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Erro ao salvar nova entidade.",
          detail:
            "Não foi possível salvar a entidade neste momento. Por favor tente mais tarde."
        });
        setLoading(false);
      }
    }
    dispatch(clearEntities());
    dispatch(setUpdateDataTable(true));
  };
  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    if (e.target.value?.length > 0) {
      setFilteredNewEntiteValues(
        newEntiteValues.filter((newEntitie) => {
          return newEntitie.value
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        })
      );
      if (
        newEntiteValues.filter((newEntitie) =>
          newEntitie.value.toLowerCase().includes(searchInput.toLowerCase())
        )?.length === 0
      ) {
        setFilteredNewEntiteValues([]);
        return setNotFound(true);
      }
      setNotFound(false);
    } else {
      setFilteredNewEntiteValues([]);
      setNotFound(false);
    }
  };
  const handleonClick = () => {
    setNewEntiteValues((oldstate) =>
      oldstate.concat({
        id: newEntiteValues?.length + 1 || 0,
        value: inputEdit
      })
    );
    setInputEdit("");
  };
  const handleEditNewEntitiesValues = (id: number, value: string) => {
    setNewEntiteValues((oldstate) =>
      oldstate.map((oldstate) => {
        if (oldstate.id === id) {
          return {
            id,
            value
          };
        }
        return oldstate;
      })
    );
    setFilteredNewEntiteValues((oldstate) =>
      oldstate.map((oldstate) => {
        if (oldstate.id === id) {
          return {
            id,
            value
          };
        }
        return oldstate;
      })
    );
  };
  const deleteNewEntitiesValues = (id: number) => {
    const oldstate = newEntiteValues;
    const newState = oldstate.filter((newentity) => newentity.id !== id);
    setNewEntiteValues(newState);
    dispatch(setUpdatedEntities());
  };

  return (
    <div className="relative h-full w-full">
      <ContainerContent>
        <div css={Title}>Entidades personalizadas</div>
        <div className="flex w-full pt-0 justify-content-start align-items-start">
          <p css={Textp}>
            Crie listas personalizadas, para coletar dados e preferências dos
            seus usuários.
          </p>
        </div>
        {!entityUpdating && entityIdUpdate === -1 ? (
          <>
            <div>Selecione o Bot para a entidade</div>
            <Dropdown
              value={botSelected}
              options={options}
              onChange={(e) => setBotSelected(e.value)}
              filter={true}
            ></Dropdown>
          </>
        ) : null}
        {/* // Input para o nome da entidade */}
        <span className="mt-2 w-full">
          <InputText
            disabled={entityUpdating && entityIdUpdate !== -1}
            className="w-full"
            value={NewEntityName}
            onChange={(e) => setNewEntityName(e.target.value)}
            placeholder="Inserir nome da entidade"
          />
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1rem",
            gap: "1rem"
          }}
        >
          <DivInputSwitch>
            <InputSwitch
              checked={regexpEntity}
              onChange={(e) => {
                setRegexpEntity(e.value);
                if (fuzzyMatching) {
                  setFuzzyMatching(false);
                }
              }}
            />
            Regex
            <span
              style={{
                marginLeft: "-10px"
              }}
              css={Textp}
              className="tooltip-Regex"
              data-pr-position="bottom"
            />
            <Tooltip
              target=".tooltip-Regex"
              style={{
                maxWidth: "600px",
                whiteSpace: "normal"
              }}
            >
              Expressões regulares são uma notação para descrever conjuntos de
              sequências de caracteres. Quando uma sequência específica pertence
              ao conjunto descrito por uma expressão regular, frequentemente
              dizemos que a expressão regular corresponde à sequência.
            </Tooltip>
          </DivInputSwitch>
          <DivInputSwitch>
            <InputSwitch
              checked={fuzzyMatching}
              onChange={(e) => {
                setFuzzyMatching(e.value);
                if (regexpEntity) {
                  setRegexpEntity(false);
                }
              }}
            />
            Fuzzy match
            <span
              style={{
                marginLeft: "-10px"
              }}
              css={Textp}
              className="tooltip-FuzzyMatch"
              data-pr-position="bottom"
            />
            <Tooltip
              target=".tooltip-FuzzyMatch"
              style={{
                maxWidth: "600px",
                whiteSpace: "normal"
              }}
            >
              O pareamento aproximado (fuzzy matching) é o tipo de extração de
              parâmetros que corresponde a uma entidade aproximadamente (em vez
              de exatamente). Ele tentará encontrar correspondências mesmo
              quando os usuários digitarem palavras incorretamente ou apenas
              parte das palavras das entradas de entidade.
            </Tooltip>
          </DivInputSwitch>
        </div>
        {!regexpEntity && (
          <div className="flex mt-3 align-items-center justify-content-center">
            <span css={Textp} />
            <div className="ml-2">
              Você pode inserir sinônimos após a vírgula Ex. São Paulo, Sampa,
              SP
            </div>
          </div>
        )}
        {/* // Input para INSERIR os valores das entidades */}
        <span className="p-input-icon-right mt-2 w-full">
          <div css={VDivisor} />
          <MyIcon
            className="pi pi-plus-circle text-blue-600"
            onClick={handleonClick}
          />
          <InputText
            className="w-full"
            value={inputEdit}
            onChange={(e) => setInputEdit(e.target.value)}
            placeholder="Adicionar entidade"
            onKeyUp={(e) => handleNewEntitiesKeypress(e)}
          />
        </span>
        {/* // Input para BUSCAR os valores das entidades */}
        <span className="p-input-icon-left mt-2 mb-2 w-full">
          <MyIcon className="pi pi-search" />
          <InputText
            className="border-round-3xl w-full"
            value={searchInput}
            onChange={(e) => handleFilter(e)}
            placeholder="Buscar"
          />
        </span>
        {/* // Input para DEMONSTRAR os valores das entidades */}
        <div className="flex flex-column w-full m-0 max-h-3rem">
          {filteredEntiteValues?.length && !notFound ? (
            filteredEntiteValues.map((newEntityValue, i) => (
              <span key={i} className="p-input-icon-right mt-2 w-full">
                <div css={VDivisor} />
                <i
                  className="pi pi-times-circle text-red-500"
                  onClick={() => deleteNewEntitiesValues(newEntityValue.id)}
                />
                <InputText
                  className="w-full"
                  value={newEntityValue.value}
                  onChange={(e) =>
                    handleEditNewEntitiesValues(
                      newEntityValue.id,
                      e.target.value
                    )
                  }
                  placeholder="Adicionar entidade"
                />
              </span>
            ))
          ) : notFound ? (
            <span className="mt-2 w-full">
              Não foram encontrados valores em sua busca.
            </span>
          ) : (
            newEntiteValues.map((newEntityValue, i) => (
              <span key={i} className="relative p-input-icon-right mt-2 w-full">
                <div css={VDivisor} />
                <MyIcon
                  className="absolute pi pi-times-circle text-red-500"
                  onClick={() => deleteNewEntitiesValues(newEntityValue.id)}
                />
                <InputText
                  className="w-full"
                  value={newEntityValue.value}
                  onChange={(e) =>
                    handleEditNewEntitiesValues(
                      newEntityValue.id,
                      e.target.value
                    )
                  }
                  placeholder="Adicionar entidade"
                />
              </span>
            ))
          )}
        </div>
      </ContainerContent>
      <ContainerButton>
        {/* <button
          type="button"
          onClick={handleSalvar}
          css={CheckButton}
          disabled={!NewEntityName?.length || !newEntiteValues?.length}
        >
          {loading ? (
            <div className="mr-2">
              <ProgressSpinner style={{ width: "20px", height: "20px" }} />
            </div>
          ) : (
            <MyImage
              disabled={!NewEntityName?.length || !newEntiteValues?.length}
              src={Check}
              alt="Check-Button"
            />
          )}
          Salvar
        </button> */}
        <Button
          label="Salvar"
          onClick={handleSalvar}
          css={CheckButton}
          disabled={!NewEntityName?.length || !newEntiteValues?.length}
        />
      </ContainerButton>
    </div>
  );
};
