/* eslint-disable no-warning-comments */
import {
  NodeData,
  iNodesSaveObject
} from "./../components/react-flow-renderer/NodeData.d";
import { Node } from "react-flow-renderer";
import { serializeBotInteractions } from "./serialize";
// TODO: Validar unique Names/Label
// TODO: Validar somente um NodeInicio e somente um NodeEnd
// TODO: Não enviar o id nos nodes
// TODO: Validar atributos.
// TODO: Validar fluxo completo. Todos os nodes interligados.
// TODO: Validar se todos terminam em um nodeEnd
// TODO: Validar a possibilidade de alteração de uma entidade em um node anterior, o que invalida os nodes posteriores que utilizam esta entidade como variável.
// TODO: Validar se os Nomes/Labels dos nodes não possuem espaço - BackEnd não aceita, retornando o erro:
// - FailedPreconditionException: Validate intent with display name 'Fim 01' failed because of the following reasons: Output context name 'Fim 01' may contain only Latin letters (a-z A-Z), digits (0-9), underscore (_), and hyphen (-). Code: FAILED_PRECONDITION"

export const getLabel = (
  nodeID = "",
  nodes: Node<NodeData>[]
): string | null => {
  const nodeLabel = nodes.find((node) => node.id === nodeID)?.data.label;
  if (nodeLabel) {
    return nodeLabel;
  }
  return null;
};

export const getLabelBotInteraction = (
  nodeID = "",
  nodes: Node<NodeData>[]
): string | null => {
  const nodeLabel = nodes
    .find((node) => node.id === nodeID)
    ?.data.label.replaceAll(" ", "_");
  if (nodeLabel) {
    return nodeLabel;
  }
  return null;
};
export const onSave = (
  name: string,
  nodes: Node<NodeData>[]
): iNodesSaveObject => {
  const saveObject = nodes.map((node, i) => {
    return {
      id: node.id,
      name: node.data.label,
      first: node.type === "startNode",
      end: node.type === "endNode",
      level: node.data.level as number,
      fallbackMessages: node.data.fallbackMessages,
      x: node.position.x,
      y: node.position.y,
      transfer: node.data?.transfer || undefined,
      transferData: node.data?.transferData || undefined,
      botInteractions: node.data.botInteractions?.map((bi, i) => {
        return {
          // id: bi.id,
          interaction: bi.text ? serializeBotInteractions(bi.text, nodes) : "",
          entityName: bi.entityName ? bi.entityName : "",
          entityTypeId: bi.entityTypeId ? bi.entityTypeId : -1,
          position: i,
          buttons: bi?.buttons || [],
          files: bi?.files
        };
      }),
      userInteractions: node.data.userInteractions?.map((ui, i) => {
        return {
          // id: ui?.id,
          source: getLabel(ui?.source, nodes) || "",
          target: getLabel(ui?.target, nodes) || "",
          entityTypeId: ui.entityTypeId ? ui.entityTypeId : -1,
          entityName: ui?.entityTypeName ? ui?.entityTypeName : "",
          phrases: ui?.phrases ? ui?.phrases : [],
          position: i
        };
      }),
      webhookCall: node.data.webhookCall || false
    };
  });
  return { name, nodes: saveObject };
};
