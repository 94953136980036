import { BluePencil } from "./../../../../../assets/index";
import { Checkbox } from "primereact/checkbox";
import { RefObject, useEffect } from "react";
import {
  CheckCard,
  ContainerLeftModal,
  ContainerRightModal,
  HiddenDiv,
  IconButton,
  Label
} from "./styles";
import DialogPadrao from "../../../../../components/DialogPadrao";
import { ChatbotScript } from "../../ChatbotScript";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setModalChatbot } from "../../../../../redux/slices/flowInteractions";
import { RightContentChatBotModal } from "../../chatBotModal/RightContent";
import { Toast } from "primereact/toast";

interface IChannelName {
  name: string;
  img: string;
  checked: boolean;
  toastRef?: RefObject<Toast>;
}

export const ChannelChatbotModal: React.FC<IChannelName> = ({
  name,
  img,
  checked,
  toastRef
}) => {
  const dispatch = useAppDispatch();
  const botToken = useAppSelector(
    (state) => state.flowInteractions.tokenActive
  );
  const modalChatbot = useAppSelector(
    (state) => state.flowInteractions.modalChatbot
  );

  const handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div>
      <div css={CheckCard} className="relative field-checkbox">
        {checked && (
          <IconButton>
            <img
              width="10px"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setModalChatbot(true));
              }}
              src={BluePencil}
              alt="Edit Icon"
            />
          </IconButton>
        )}
        <img src={img} alt={`img-${name}`} />
        <Label htmlFor={name}>{name}</Label>
        <HiddenDiv>
          <Checkbox inputId={name} checked={checked} />
        </HiddenDiv>
      </div>
      <DialogPadrao
        displayDialog={modalChatbot}
        contentStyle={{ height: "420px" }}
        onHide={() => {
          dispatch(setModalChatbot(false));
        }}
        headerStyle={{
          backgroundColor: "#ff0025"
        }}
        onClick={handleModalClick}
      >
        <ContainerLeftModal>
          <ChatbotScript botToken={botToken} />
        </ContainerLeftModal>
        <ContainerRightModal>
          <RightContentChatBotModal />
        </ContainerRightModal>
      </DialogPadrao>
    </div>
  );
};
